export const animatedStyledHeading = () => {
  const { gsap, ScrollTrigger } = window;

  const timelines = new Map();

  function getTextDimensions(textElement) {
    const fontFamily = window.getComputedStyle(textElement).fontFamily;
    const fontSize = window.getComputedStyle(textElement).fontSize;
    const lineHeight = parseFloat(window.getComputedStyle(textElement).lineHeight) / parseFloat(fontSize);
  
    const stemLettersRegex = /[gjpqy]/g;  // Regex to adjust for descending letters
    const headingText = textElement.textContent.replace(stemLettersRegex, '');
    const measurementText = headingText.length > 0 ? headingText : 'M';
  
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize} ${fontFamily}`;
  
    const heightMetrics = context.measureText(measurementText);
    const height = (heightMetrics.actualBoundingBoxAscent + heightMetrics.actualBoundingBoxDescent) * lineHeight;

    // Measure width without removing any characters
    const widthMetrics = context.measureText(textElement.textContent);
    const width = parseFloat(widthMetrics.width.toFixed(6));
  
    return { height, width };
  }
  
  function scaleText(textElement, scale) {
    textElement.style.transform = `scale(${scale})`;
  }
  
  function updateOrCreateTimeline(el, shouldRecreate = false) {
    const svgElement = el.querySelector('svg');
    const textElements = el.querySelectorAll('svg > g > text');
    if (textElements.length === 0) return; 
  
    const dimensionsStart = getTextDimensions(textElements[0]);
    const dimensionsEnd = getTextDimensions(textElements[1]);
  
    if (dimensionsStart.width > dimensionsEnd.width) {
      scaleText(textElements[0], dimensionsEnd.width / dimensionsStart.width);
    } else if (dimensionsEnd.width > dimensionsStart.width) {
      scaleText(textElements[1], dimensionsStart.width / dimensionsEnd.width);
    }

    let textDisplayTl = false;

    if(timelines.has(el)){
      textDisplayTl = timelines.get(el);
    }
    if (!textDisplayTl || shouldRecreate) {
      
      textDisplayTl = createTimeline(el);

      if (svgElement) {
        svgElement.setAttribute('height', dimensionsEnd.height);
        svgElement.setAttribute('width', dimensionsEnd.width);
      }

      timelines.set(el, textDisplayTl);
      return textDisplayTl;
    }

    if (svgElement) {
      svgElement.setAttribute('height', dimensionsEnd.height);
      svgElement.setAttribute('width', dimensionsEnd.width);
    }
  }

  function createTimeline(el) {
    const textsGroupEl = el.querySelector('svg > g');
    const [text_1, text_2] = textsGroupEl.querySelectorAll('text');
    const filterId = el.querySelector('svg filter').id;
    const feBlur = document.querySelector(`#${filterId} > feGaussianBlur`);
    const feColorMatrix = document.querySelector(`#${filterId} > feColorMatrix`);
    let primitiveValues = { stdDeviation: 0 };
    
    const textDisplayTl = gsap.timeline({
      paused: true,
      invalidateOnRefresh: true,
      onStart: () => {
        textsGroupEl.style.filter = `url(#${filterId})`
      },
      onComplete: () => {
        textsGroupEl.style.filter = 'none';
      },
      onReverseComplete: () => {
        textsGroupEl.style.filter = 'none';
      },
      onUpdate: () => {
        feBlur.setAttribute('stdDeviation', primitiveValues.stdDeviation)
      }
    })
    .to(primitiveValues, { duration: 0.8, ease: "Power1.easeInOut", startAt: { stdDeviation: 0 }, stdDeviation: 1 }, 0)
    .to(primitiveValues, { duration: 0.8, ease: "Power1.easeInOut", stdDeviation: 0 }, '+=0')
    // .to(primitiveValues, { duration: 0.8, ease: "Power1.easeInOut", startAt: { stdDeviation: 1 }, stdDeviation: 0 })
    .to(text_1, { 
      duration: 1.6, 
      ease: "Power1.easeInOut", 
      opacity: 0,
    }, 0)
    .to(text_2, { 
      duration: 1.6, 
      ease: "Power1.easeInOut", 
      opacity: 1,
    }, 0)
    .to({
      a: 10, b: -3
    }, {
      duration: 0.4,
      a: 1, 
      b: 0,
      ease: "Power1.easeInOut",
      onUpdate: function() {
        feColorMatrix.setAttribute('values', `1 0 0 0 0  
        0 1 0 0 0  
        1 0 1 0 0  
        0 0 0 ${this.targets()[0].a} ${this.targets()[0].b}`);
      }
    }, '+=0')
    .to({
      a: 10, b: -3
    }, {
      duration: 0.4,
      a: 1, 
      b: 0,
      ease: "Power1.easeInOut",
      onUpdate: function() {
        feColorMatrix.setAttribute('values', `1 0 0 0 0  
        0 1 0 0 0  
        1 0 1 0 0  
        0 0 0 ${this.targets()[0].a} ${this.targets()[0].b}`);
      }
    }, '+=1.2');

    return textDisplayTl;
  }

  function applyScrollInteraction(el) {
    const textsGroupEl = el.querySelector('svg > g');
    const filterId = el.querySelector('svg filter').id;
    const textDisplayTl = updateOrCreateTimeline(el, true);
    // const feColorMatrix = document.querySelector(`#${filterId} > feColorMatrix`);

    if (ScrollTrigger.getById(el.id)) {
      ScrollTrigger.getById(el.id).kill();
    }

    gsap.to({}, {
      scrollTrigger: {
        trigger: el,
        start: "top 60%",
        end: "bottom 40%",
        onEnter: () => {
          textsGroupEl.style.filter = `url(#${filterId})`;
          textDisplayTl.play();
        },
        onLeaveBack: () => {
          textsGroupEl.style.filter = `url(#${filterId})`;
          textDisplayTl.reverse();
        },
        invalidateOnRefresh: true
      }
    });
  }

  const checkFontsLoaded = (fontNames, callback) => {
    let loadedFonts = 0;
    const fontLoadTimeout = 2000;
    let timeoutHandles = new Array(fontNames.length).fill(null);

    fontNames.forEach((fontName, index) => {
      let testElement = document.createElement('span');
      testElement.innerHTML = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      testElement.style.cssText = `
        position: absolute;
        top: -9999px;
        left: -9999px;
        visibility: hidden;
        font-family: sans-serif;
        font-size: 48px;
      `;
      document.body.appendChild(testElement);
      const initialWidth = testElement.offsetWidth;
      testElement.style.fontFamily = `"${fontName}", sans-serif`;

      const checkWidth = () => {
        if (testElement.offsetWidth !== initialWidth) {
          clearTimeout(timeoutHandles[index]);
          document.body.removeChild(testElement);
          loadedFonts++;
          if (loadedFonts === fontNames.length) {
            callback(true);
          }
        } else if (document.body.contains(testElement)) {
          requestAnimationFrame(checkWidth);
        }
      };
      
      timeoutHandles[index] = setTimeout(() => {
        if (document.body.contains(testElement)) {
          document.body.removeChild(testElement);
          loadedFonts++;
          if (loadedFonts === fontNames.length) {
            callback(true);
          }
        }
      }, fontLoadTimeout);

      checkWidth();
    });
  };

  const handleResize = () => {
    document.querySelectorAll('.heading-text-container').forEach(el => {
      updateOrCreateTimeline(el);
    });
  };

  window.addEventListener('resize', handleResize);

  checkFontsLoaded(['Singolare Layers', 'Singolare Bold'], () => {  
    document.querySelectorAll('.heading-text-container').forEach(el => {
      applyScrollInteraction(el);
    });
  });
};
