import Splide from '@splidejs/splide';

export const imageSlider = () => {
  const imageSliderContainer = document.querySelectorAll('.image-splide');
  if (imageSliderContainer.length === 0) return;

  imageSliderContainer.forEach(imageSlider => {
    const sliderData = JSON.parse(imageSlider.getAttribute('data-slider'));
    const { sliderId } = sliderData;
        
    if(sliderData && sliderId) {
      imageSlider.removeAttribute('data-slider');
    }

    new Splide(`.${sliderId}`, {
      type: 'loop',
      gap: 20,
      arrows: false,
      easing: 'cubic-bezier(0.83, 0, 0.17, 1)',
      lazyLoad: 'nearby',
      autoplay: true,
      arrows: true,
      interval: 5000,
      speed: 1000,
    }).mount();
  });
}