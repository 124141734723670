import { homeHeroSlider } from "./_home-hero-slider.js";
import { horizontalSlider } from "./_horizontal-slider.js";
import { imageSlider } from "./_image-slider.js";
import { teamSlider } from "./_team-slider.js";


export const slidersInit = () => {
  homeHeroSlider();
  imageSlider();
  teamSlider();
  horizontalSlider();
}