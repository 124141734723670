import * as PIXI from 'pixi.js';
import gsap from 'gsap';

export function initTextEffect(text) {
    const textFilter = new PIXI.ColorMatrixFilter();
    textFilter.saturate(2);
    
    const gsapTimeline = gsap.timeline();
    gsapTimeline
        .to(textFilter, {contrast: 2, duration: 0.2})
        .to(textFilter, {contrast: 1, duration: 0.2});

    return { filter: textFilter, animation: gsapTimeline };
}

export function initImageEffectOne(image) {
    const imageFilter = new PIXI.NoiseFilter();
    imageFilter.noise = 0.5;
    
    const gsapTimeline = gsap.timeline();
    gsapTimeline
        .to(imageFilter, {noise: 0.2, duration: 0.2})
        .to(imageFilter, {noise: 0.5, duration: 0.2});

    return { filter: imageFilter, animation: gsapTimeline };
}

export function initImageEffectTwo(image) {
    const imageFilter = new PIXI.NoiseFilter();
    imageFilter.size = 2;
    
    const gsapTimeline = gsap.timeline();
    gsapTimeline
        .to(imageFilter, {size: 4, duration: 0.2})
        .to(imageFilter, {size: 2, duration: 0.2});

    return { filter: imageFilter, animation: gsapTimeline };
}
