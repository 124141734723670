import * as PIXI from 'pixi.js';
import { initTextEffect, initImageEffectOne, initImageEffectTwo } from './canvas-effects.js';
import gsap from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin.js';

let backgroundApp;
let overlayApp;
let backgroundContainer;
let overlayContainer;
const assetsPath = `${window.location.origin}/wp-content/themes/bakerbarrios-theme/resources/images`;
let textures = {};
const elementCanvases = {};

export const Canvas = () => {
  gsap.registerPlugin(PixiPlugin);
  PixiPlugin.registerPIXI(PIXI);

  initBackgroundCanvas();
  // initOverlayCanvas();
  
  initForegroundElements();
  
  if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(console.error);
    import.meta.webpackHot.dispose(() => {
      overlayApp.destroy(true);
      backgroundApp.destroy(true);
      foregroundApp.destroy(true);
    });
  }
}

/* Overlay Canvas */
const initOverlayCanvas = () => {
  if (!document.querySelector('.overlay-container')) {
    overlayApp = new PIXI.Application({
      autoDensity: true,
      resolution: window.devicePixelRatio,
      backgroundAlpha: 0,
      antialias: true,
      resizeTo: window
    });

    document.body.appendChild(overlayApp.view);
    overlayApp.view.className = 'overlay-container';
    overlayApp.view.style.zIndex = 15;

    if (overlayApp?.stage) {
      overlayApp.stage.sortableChildren = true;

      overlayContainer = new PIXI.Container();
      overlayContainer.zIndex = 15;
      overlayApp.stage.addChild(overlayContainer);
    }
  }
  overlayElements();
}

/* Background Canvas */
const initBackgroundCanvas = () => {
  if (!document.querySelector('.background-container')) {
    backgroundApp = new PIXI.Application({
      autoDensity: true,
      resolution: window.devicePixelRatio,
      backgroundAlpha: 0,
      antialias: true,
      resizeTo: window
    });

    document.body.appendChild(backgroundApp.view);
    backgroundApp.view.className = 'background-container';
    backgroundApp.view.style.zIndex = 15;

    if (backgroundApp?.stage) {
      backgroundApp.stage.sortableChildren = true;

      backgroundContainer = new PIXI.Container();
      backgroundContainer.zIndex = 15;
      backgroundApp.stage.addChild(backgroundContainer);
    }
  }
  backgroundElements();
}

const initForegroundElements = () => {
  const assets = [
    {
      selector: '.effect-foreground-one', 
      canvasImage: {
        url: `${assetsPath}/overlay_gradient_01.png`,
        x: 'element.getBoundingClientRect().left - element.offsetWidth * 2',
        y: 'element.offsetHeight * 4',
        width: 'element.offsetWidth * 3',
        height: 'element.offsetWidth * 1.11',
        scaleX: 0.25,
        scaleY: 0.25,
        rotation: '22.5',
        alpha: 0.5,
        blendMode: PIXI.BLEND_MODES.NORMAL
      }, 
      animateTo: {
        pixi: {
          x: 'element.getBoundingClientRect().left + element.offsetWidth * 1.25',
          y: '-window.innerHeight',
          scaleX: 1,
          scaleY: 1,
          alpha: 0
        },
        scrollTrigger: {
          // trigger: 'element',
          // start: "top-=10% bottom",
          // end: "top+=80% top",        
          // scrub: 2.5
          trigger: 'element',
          start: "top top+=50%",
          end: "bottom top",          
          toggleActions: "play none none reverse",
        },
        ease: "power2.inOut",
        duration: 8
      }
    },
    {
      selector: '.effect-foreground-hero-home', 
      canvasImage: {
        url: `${assetsPath}/overlay_gradient_01.png`,
        x: 'element.getBoundingClientRect().left - element.offsetWidth * 1.25',
        y: 'element.offsetHeight * 1.25',
        width: 'window.innerWidth * 3',
        height: '(window.innerWidth * 0.37) * 3',
        scaleX: 0.4,
        scaleY: 0.4,
        alpha: 0.35,
        blendMode: PIXI.BLEND_MODES.ADD,
        rotation: '67.5'
      }, 
      animateTo: {
        pixi: {
          x: 'element.getBoundingClientRect().left + element.offsetWidth * 1.25',
          y: '-element.offsetHeight * 0.8',
          scaleX: 0.75,
          scaleY: 0.75,
          alpha: 0,
        },
        scrollTrigger: {
          trigger: 'element',
          start: "top+=2 top",
          end: "bottom top",          
          // scrub: 5,
          toggleActions: "play none none reverse",
        },
        ease: "power2.inOut",
        duration: 5
      },
      graphicTimeout: 1000
    },
    {
      selector: '.effect-foreground-hero-interior', 
      canvasImage: {
        url: `${assetsPath}/overlay_gradient_01.png`,
        x: 'element.offsetWidth - element.offsetHeight / 2',
        y: 'element.offsetHeight * 0.8',
        // y: 'element.offsetHeight * 0.5',
        width: 'element.offsetWidth * 1.25',
        height: 'element.offsetHeight',
        scaleX: 0.25,
        scaleY: 0.25,
        alpha: 0.35,
        blendMode: PIXI.BLEND_MODES.NORMAL,
        rotation: '102.5'
        // rotation: '29'
      }, 
      animateTo: {
        pixi: {
          x: 'window.innerWidth',
          y: 'element.offsetHeight * 1.5',
          // y: 'element.offsetHeight * 1.25',
          scaleX: 0.75,
          scaleY: 0.75,
          alpha: 0,
        },
        scrollTrigger: {
          trigger: 'element',
          start: "top-=50 top",
          end: "bottom top",       
          toggleActions: "play none none reverse",
          // scrub: 2
        },
        ease: "power2.inOut",
        duration: 5,
      }
    },
    {
      selector: '.effect-foreground-hero-influence', 
      canvasImage: {
        url: `${assetsPath}/overlay_gradient_01.png`,
        x: 'element.getBoundingClientRect().left + element.offsetWidth / 1.5',
        // y: '(window.innerWidth * 0.37) / 3',
        y: 300,
        width: 'window.innerWidth',
        height: 'window.innerWidth * 0.37',
        scaleX: 0.75,
        scaleY: 0.75,
        alpha: 0.25,
        blendMode: PIXI.BLEND_MODES.ADD,
        rotation: '0'
      }, 
      animateTo: {
        pixi: {
          x: 'element.getBoundingClientRect().left + element.offsetWidth * 3',
          y: '-window.innerHeight',
          scaleX: 1,
          scaleY: 1,
          alpha: 0.5,
        },
        scrollTrigger: {
          trigger: 'element',
          start: "top-=25% top",
          end: "top+=50% top",              
          scrub: 2
        },
        ease: "power2.inOut"
      }
    }
  ];

  assets.forEach(asset => {
    foregroundElements({selector: asset.selector, canvasImage: asset.canvasImage, animateTo: asset.animateTo, graphicTimeout: asset?.graphicTimeout ?? 0} )
  });
}

const addGraphics = async (assets, container) => {
  const loadTexture = async (url) => {
    if (!textures[url]) {
      textures[url] = PIXI.Texture.from(url);
      await new Promise((resolve) => {
        textures[url].baseTexture.once('loaded', resolve);
        textures[url].baseTexture.once('error', () => {
          console.error(`Failed to load texture from ${url}`);
          resolve();
        });
      });
    }
    return textures[url];
  };

  const createSprite = (texture, options, animate) => {
    const sprite = new PIXI.Sprite(texture);
    sprite.anchor.set(0.5);
    sprite.position.set(
      options.x || 0 - (options.width || texture.width) / 2,
      options.y || 0 - (options.height || texture.height) / 2
    );

    if (options.rotation) {
      sprite.angle = options.rotation;
    }

    sprite.width = options.width || texture.width;
    sprite.height = options.height || texture.height;
    sprite.alpha = options.alpha || 1;
    sprite.blendMode = options.blendMode || PIXI.BLEND_MODES.NORMAL;
    container.addChild(sprite);

    if (animate) {
      animateGraphic(sprite, animate);
    }
  };

  for (const asset of assets) {
    const { url, options, animate } = asset;
    try {
      const texture = await loadTexture(url);
      if (texture.baseTexture.valid) {
        createSprite(texture, options, animate);
      }
    } catch (error) {
      console.error(`Error processing asset ${url}:`, error);
    }
  }
};

const animateGraphic = (sprite, animate) => {
  const { from, to } = animate;
  gsap.fromTo(sprite, from, to);
}

const overlayElements = async (numCircles = 5) => {
  const assets = [
    { 
      url: `${assetsPath}/overlay_gradient_01.png`, 
      options: { 
        x: (-window.innerWidth * 2), 
        y: window.innerHeight * 5,
        width: (window.innerWidth * 1.25),
        height: ( (window.innerWidth * 1.25) * 0.37),
        alpha: 0.5,
        blendMode: PIXI.BLEND_MODES.LIGHTEN 
      },
      animate: {
        from: { 
          pixi: { 
            x: (-window.innerWidth * 2),
            y: window.innerHeight * 5,
            scaleX: 0.25,
            scaleY: 0.25
          }
        },
        to: { 
          pixi: { 
            x: window.innerWidth + (window.innerWidth * 1.25), 
            y: -window.innerHeight * 1.5,
            scaleX: 1,
            scaleY: 1
          },
          scrollTrigger: {
            trigger: 'main > section:nth-child(1)',
            start: "top 85%",
            end: "bottom 15%",
            scrub: 1.5
          },
          ease: "power2.inOut",
          immediateRender: false
        },
      }   
    },
  ];

  await addGraphics(assets, overlayContainer);
  // createCircles(numCircles, 5, 5, '#FF5A97', overlayContainer);
  // animateCirclesPerSection(numCircles);
}

const elementCanvas = (element, canvasClass) => {
  return new Promise((resolve) => {
    let elementId = element.id || `elementCanvas_${Math.random().toString(36).substr(2, 9)}`;
    let elementCanvas, canvasContainer;

    if (!element.id) {
      element.id = elementId;
    }

    if (elementCanvases[elementId]) {
      resolve(elementCanvases[elementId]);
      return;
    }

    elementCanvas = new PIXI.Application({
      autoDensity: true,
      resolution: window.devicePixelRatio,
      backgroundAlpha: 0,
      resizeTo: element,
      antialias: true,
      width: element.offsetWidth,
      height: element.offsetHeight,
    });

    element.appendChild(elementCanvas.view);
    element.style.position = 'relative';
    elementCanvas.view.className = canvasClass;
    elementCanvas.view.style.zIndex = '5';
    elementCanvas.view.style.position = 'absolute';
    elementCanvas.view.style.top = '0';
    elementCanvas.view.style.left = '0';
    
    if (elementCanvas?.stage) {
      elementCanvas.stage.sortableChildren = true;

      canvasContainer = new PIXI.Container();
      canvasContainer.zIndex = 1;
      elementCanvas.stage.addChild(canvasContainer);
    }
    
    elementCanvases[elementId] = { canvas: elementCanvas, container: canvasContainer };
    
    elementCanvas.ticker.add(() => {
      resolve(elementCanvases[elementId]);
    }, { once: true });
  });
};

const foregroundElements = ({
  selector = '', 
  canvasImage = {
    url: `${assetsPath}/overlay_gradient_01.png`,
    x: 'element.getBoundingClientRect().left - element.offsetWidth * 2',
    y: 'element.offsetHeight * 3',
    width: 'element.offsetWidth',
    height: 'element.offsetHeight',
    scaleX: 0.125,
    scaleY: 0.125,
    alpha: 0.5,
    blendMode: PIXI.BLEND_MODES.NORMAL
  }, 
  animateTo = {
    pixi: {
      x: 'element.getBoundingClientRect().left + element.offsetWidth * 2',
      y: '-window.innerHeight * 1.5',
      scaleX: 0.75,
      scaleY: 0.75
    },
    scrollTrigger: {
      trigger: 'element',
      start: "top+=15% bottom",
      end: "bottom-=10% top",
    },
    ease: "power2.inOut"
  },
  graphicTimeout = 0
}) => {
  let assets = {
    classSelector: `.baba-animate${selector}`,
    canvasImage: canvasImage,
    animateTo,
    graphicTimeout
  };
  createAssetAnimations(assets);
}

const createAssetAnimations = ({
  classSelector,
  canvasImage = {
    url: null,
    x: 'element.getBoundingClientRect().left - element.offsetWidth * 2',
    y: 'element.offsetHeight * 3',
    width: 'element.offsetWidth',
    height: 'element.offsetHeight',
    scaleX: 0.125,
    scaleY: 0.125,
    alpha: 0.5,
    blendMode: PIXI.BLEND_MODES.NORMAL,
    rotation: 0,
  },
  animateTo = {
    pixi: {
      x: 'element.getBoundingClientRect().left + element.offsetWidth * 2',
      y: '-window.innerHeight * 1.5',
      scaleX: 0.75,
      scaleY: 0.75
    },
    scrollTrigger: {
      start: "top center",
      end: "bottom center",
    },
    ease: "power2.inOut"
  },
  graphicTimeout
} = {}) => {
  const elements = document.querySelectorAll(classSelector);
  const assets = [];
  const canvasClass = `foreground-canvas ${classSelector.replace('.baba-animate.', '')}`;

  elements.forEach(async element => {

    const { canvas, container } = await elementCanvas(element, canvasClass);
    const { trigger, ...scrollTriggerSettings } = animateTo.scrollTrigger;
    const animationTrigger = trigger == 'element' ? element : evaluateProperty(trigger, element);


    const asset = {
      url: canvasImage.url || `${assetsPath}/overlay_gradient_01.png`,
      options: {
        x: evaluateProperty(canvasImage.x, element),
        y: evaluateProperty(canvasImage.y, element),
        width: evaluateProperty(canvasImage.width, element),
        height: evaluateProperty(canvasImage.height, element),
        scaleX: canvasImage.scaleX,
        scaleY: canvasImage.scaleY,
        alpha: canvasImage.alpha,
        blendMode: canvasImage.blendMode,
        rotation: canvasImage.rotation
      },
      animate: {
        from: {
          pixi: {
            x: evaluateProperty(canvasImage.x, element),
            y: evaluateProperty(canvasImage.y, element),
            scaleX: canvasImage.scaleX,
            scaleY: canvasImage.scaleY,
            alpha: canvasImage.alpha,
          }
        },
        to: {
          ...animateTo,
          pixi: {
            ...animateTo.pixi,
            x: evaluateProperty(animateTo.pixi.x, element),
            y: evaluateProperty(animateTo.pixi.y, element),
          },
          scrollTrigger: {
            ...scrollTriggerSettings,
            trigger: animationTrigger
          },
        }
      },
      graphicTimeout
    };
    
    setTimeout(async () => {
      await addGraphics([asset], container);
    }, graphicTimeout);
  });
}

const evaluateProperty = (expression, element) => {
  if (typeof expression === 'string' && (expression.includes('element.') || expression.includes('window.') || expression.includes('document.'))) {
    try {
      return Function('element', `return ${expression};`)(element);
    } catch (e) {
      console.error('Error evaluating expression:', expression, e);
    }
  }
  return expression;
}

const backgroundElements = () => {
  createCircles(8, 10, 10, '#FB5903', backgroundContainer);
}

const handleElements = () => {
  const elements = document.querySelectorAll('.baba-animate');
  if (elements && elements.length > 0) {
    elements.forEach(element => {
      let classes = Array.from(element.classList);
      let effectClass = classes.find(cls => cls.startsWith('effect-'));

      if (!effectClass) {
        return;
      }

      let pixiContainer;
      switch (effectClass) {
        case 'effect-text-hover':
          pixiContainer = initTextEffect(element);
          break;
        case 'effect-img-hover-one':
          pixiContainer = initImageEffectOne(element);
          break;
        case 'effect-img-hover-two':
          pixiContainer = initImageEffectTwo(element);
          break;
      }

      if (pixiContainer) {
        if (element.classList.contains('background-effect')) {
          backgroundContainer.addChild(pixiContainer);
        } else {
          if (overlayContainer) {
            overlayContainer.addChild(pixiContainer);
          }
        }
      }
    });
  }
}

const createCircles = (numCircles, xDisplacement, yDisplacement, hexColor = '#CC202D', container) => {
  if(overlayApp?.screen){
    for (let i = 0; i < numCircles; i++) {
      const radius = Math.random() + ((Math.random() * 50) + 150);
      const svgString = `
            <svg width="${radius * 2}" height="${radius * 2}" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <radialGradient id="grad${i}">
                        <stop offset="10%" stop-color="${hexColor}"/>
                        <stop offset="100%" stop-color="${hexColor}" stop-opacity="0"/>
                    </radialGradient>
                </defs>
                <circle cx="${radius}" cy="${radius}" r="${radius}" fill="url(#grad${i})"/>
            </svg>`;
      const texture = PIXI.Texture.from(svgString);
      const sprite = new PIXI.Sprite(texture);
      sprite.blendMode = PIXI.BLEND_MODES.SCREEN;
      sprite.alpha = 0.3;
      sprite.position.set(overlayApp.screen.width / 2 + xDisplacement - radius, overlayApp.screen.height / 2 + yDisplacement - radius);
      container.addChild(sprite);
    }
  }
}

const animateCirclesPerSection = (numCircles) => {
  const sections = document.querySelectorAll('main > section, main > div.wp-block-group');
  const positions = {};

  sections.forEach((section, index) => {
    positions[index] = Array.from({ length: numCircles }).map(() => ({
      x: Math.random() * (window.innerWidth / 2),
      y: Math.random() * (window.innerHeight / 2),
      scale: 1 + (Math.random() * 0.1 - 0.05),
    }));
  });

  overlayContainer.children.slice(-numCircles).forEach((circle, circleIndex) => {
    sections.forEach((section, sectionIndex) => {
      gsap.fromTo(circle,
        {
          pixi: {
            x: () => circle.x,
            y: () => circle.y,
            scaleX: () => circle.scale.x,
            scaleY: () => circle.scale.y
          }
        },
        {
          pixi: positions[sectionIndex][circleIndex],
          scrollTrigger: {
            trigger: section,
            start: "top center",
            end: "bottom center",
            toggleActions: "play none none reverse"
          },
          ease: "power2.inOut",
          duration: 2
        }
      );
    });
  });
}
