import Alpine from 'alpinejs';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { Canvas } from '../canvas/canvas-setup.js';

/*** Start Alpine Persist Plugin (v3.12.1) ***/

function alpinePersist(Alpine) {
  let persist = () => {
    let alias;
    let storage = localStorage;
    return Alpine.interceptor((initialValue, getter, setter, path, key) => {
      let lookup = alias || `_x_${path}`;
      let initial = storageHas(lookup, storage) ? storageGet(lookup, storage) : initialValue;
      setter(initial);
      Alpine.effect(() => {
        let value = getter();
        storageSet(lookup, value, storage);
        setter(value);
      });
      return initial;
    }, (func) => {
      func.as = (key) => {
        alias = key;
        return func;
      }, func.using = (target) => {
        storage = target;
        return func;
      };
    });
  };
  Object.defineProperty(Alpine, "$persist", { get: () => persist() });
  Alpine.magic("persist", persist);
  Alpine.persist = (key, { get, set }, storage = localStorage) => {
    let initial = storageHas(key, storage) ? storageGet(key, storage) : get();
    set(initial);
    Alpine.effect(() => {
      let value = get();
      storageSet(key, value, storage);
      set(value);
    });
  };
}
function storageHas(key, storage) {
  return storage.getItem(key) !== null;
}
function storageGet(key, storage) {
  return JSON.parse(storage.getItem(key, storage));
}
function storageSet(key, value, storage) {
  storage.setItem(key, JSON.stringify(value));
}

/*** End Alpine Persist Plugin ***/

export function dataStore() {
  window.Alpine = Alpine;
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  window.gsap = gsap;
  window.ScrollTrigger = ScrollTrigger;

  window.Alpine.plugin(alpinePersist); // Init persist plugin

  window.Alpine.store('siteData', {
    state: {
      env: null,
      scrollPos: 0,
    },
    team: {
      indexPosition: 0,
    },
    app: null,
    backgroundContainer: null,
    overlayContainer: null,
    navigation: {
      menuOpen: false,
      search: false,
      submenuOpen: {},
    },
    lightbox: {
      container: null,
      galleryData: {},
      imageData: {},
    },
    contentGrid: {
      'project': {
        filters: {
          industries: { terms: [], active: [] },
          services: { terms: [], active: [] }
        },
        data: {}
      },
      'influence': {
        filters: {
          industries: { terms: [], active: [] },
          services: { terms: [], active: [] }
        },
        data: {}
      },
    },
    init() {
      this.envInit();
      this.frontendInit();
      this.lightboxInit();
      // this.contentGridInit();
      this.influenceGridBackground('init');

      if (this.app == null) {
        this.app = Canvas();
      }
    },
    envInit() {
      const { hostname } = window.location;

      if (/local/.test(hostname)) {
        this.state.env = 'development';
        this.devInit();
      } else if (/staging/.test(hostname)) {
        this.state.env = 'staging';
      } else {
        this.state.env = 'production';
      }
    },
    devInit() {
      // Development environment init
      console.log('siteData init');
    },
    frontendInit() {
      if (!document.body.classList.contains('block-editor-page')) {
        // Frontend
        this.contentGridInit();
      } else {
       // Editor
      }
    },
    lightboxInit() {
      if (this.lightbox.galleryData != {}) {
        this.lightbox.galleryData = {};
      }
      if (this.lightbox.galleryData != {}) {
        this.lightbox.galleryData = {};
      }
    },
    contentGridInit() {
      document.querySelectorAll('[data-grid-id]').forEach((element) => {
        const gridId = element.getAttribute('data-grid-id');

        if (gridId) {
          this.contentGrid[gridId] = this.contentGrid[gridId] || {
            filters: {},
            data: {}
          };

          const parentChildTerms = {};

          const uniqueTerms = (termsArray, filter) => {
            if (!this.contentGrid[gridId].filters[filter]) {
              this.contentGrid[gridId].filters[filter] = {
                status: {
                  title: '',
                  open: false,
                },
                terms: [],
                active: []
              };
            }

            termsArray.forEach(term => {
              this.contentGrid[gridId].filters[filter].terms.push(term);
            });

            const uniqueTerms = [{ name: 'Select All', slug: 'select-all' }];
            const termSlugs = new Set(['select-all']);

            this.contentGrid[gridId].filters[filter].terms.forEach(term => {
              if (!termSlugs.has(term.slug)) {
                uniqueTerms.push(term);
                termSlugs.add(term.slug);
              }
            });

            this.contentGrid[gridId].filters[filter].terms = uniqueTerms;
            this.contentGrid[gridId].filters[filter].active = [...uniqueTerms];
          };

          const nestedTerms = (termsArray, filter) => {
            if (!this.contentGrid[gridId].filters[filter]) {
              this.contentGrid[gridId].filters[filter] = {
                status: {
                  title: '',
                  open: false,
                },
                terms: [],
                active: []
              };
            }

            termsArray.forEach(term => {
              const parts = term.slug.split("-");
              const parentTermSlug = parts.pop();

              if (!parentChildTerms[parentTermSlug]) {
                parentChildTerms[parentTermSlug] = {
                  name: parentTermSlug,
                  slug: parentTermSlug,
                  children: [],
                  open: false,
                };
              }

              if (!parentChildTerms[parentTermSlug].children.some(child => child.slug === term.slug)) {
                parentChildTerms[parentTermSlug].children.push(term);
              }
            });

            Object.values(parentChildTerms).forEach(parentTerm => {
              parentTerm.children.sort((a, b) => b.slug.localeCompare(a.slug));
            });

            this.contentGrid[gridId].filters[filter].terms = [{ name: 'Select All', slug: 'select-all' }, ...Object.values(parentChildTerms).sort((a, b) => Number(b.name) - Number(a.name))]
            const allTerms = Object.values(parentChildTerms).reduce((acc, parentTerm) => {
              acc.push(parentTerm);
              if (parentTerm.children) {
                acc.push(...parentTerm.children);
              }
              return acc;
            }, []);

            this.contentGrid[gridId].filters[filter].active = [{ name: 'Select All', slug: 'select-all' }, ...allTerms];
          };

          this.contentGridAnimations('init');

          element.querySelectorAll('.content-grid-item').forEach((item) => {
            for (const key in item.dataset) {
              const value = JSON.parse(item.dataset[key].replace(/'/g, '"'));
              if (key == 'date') {
                nestedTerms(value, key);
              } else {
                uniqueTerms(value, key);
              }
            }
          });
        }
      });
    },
    updateContentFilter(gridId, id, filterId, parentTerm = false) {
      let selection = this.contentGrid[gridId].filters[filterId].active;

      if (id == 'select-all') {
        this.toggleAllFilterTerms(gridId, filterId);
        return;
      }

      const index = selection.findIndex(term => term.id === id || term.slug === id);

      const termInSelection = selection.some(term => term.slug === id);

      if (termInSelection) {
        const removedTerm = selection.find(term => term.slug === id);

        if (removedTerm) {
          const idsToRemove = new Set([removedTerm.slug]);

          if (removedTerm.children) {
            removedTerm.children.forEach(child => {
              idsToRemove.add(child.slug);
            });
          }

          this.contentGrid[gridId].filters[filterId].active = selection.filter(term => !idsToRemove.has(term.slug));
        }
      }
      else {
        let termObject;

        if (parentTerm) {
          const parentTermObject = this.contentGrid[gridId].filters[filterId].terms.find(term => term.id === parentTerm || term.slug === parentTerm);
          if (parentTermObject && parentTermObject.children) {
            termObject = parentTermObject.children.find(child => child.id === id || child.slug === id);
          }
        } else {
          termObject = this.contentGrid[gridId].filters[filterId].terms.find(term => term.id === id || term.slug === id);
        }

        if (termObject) {
          this.contentGrid[gridId].filters[filterId].active.push(termObject);

          if (termObject.children) {
            termObject.children.forEach(child => {
              this.contentGrid[gridId].filters[filterId].active.push(child);
            });
          }
        }
      }
      this.contentGridAnimations('updateFilter');
    },
    contentFilterValActive(gridId, slug, filterId) {
      const selection = this.contentGrid[gridId].filters[filterId].active;
      return selection.some(term => term.slug === slug);
    },
    contentGridAnimations(state = 'init') {
      if (state === 'init') {
        gsap.to(".content-grid-item:nth-child(n+4)", { opacity: 0, y: 20 });
        ScrollTrigger.batch(".content-grid-item", {
          onEnter: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseIn", opacity: 1, y: 0, stagger: 0.15 }),
          onLeave: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseOut", opacity: 0, y: 20 }),
          onEnterBack: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseIn", opacity: 1, y: 0, stagger: 0.15 }),
          onLeaveBack: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseOut", opacity: 0, y: 20 }),
          start: "top 85%",
          end: "bottom 15%",
        });

        return;
      }

      if (state === 'updateFilter') {
        gsap.set(".content-grid-item", { clearProps: "opacity,y" });

        let instances = ScrollTrigger.getAll();
        instances.forEach(instance => {
          instance.kill();
        });

        setTimeout(() => {
          ScrollTrigger.refresh(true);
          ScrollTrigger.clearScrollMemory();

          ScrollTrigger.batch(".content-grid-item-active", {
            onEnter: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseIn", opacity: 1, y: 0, stagger: 0.15 }),
            onLeave: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseOut", opacity: 0, y: 20 }),
            onEnterBack: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseIn", opacity: 1, y: 0, stagger: 0.15 }),
            onLeaveBack: batch => gsap.to(batch, { duration: 0.5, ease: "customEaseOut", opacity: 0, y: 20 }),
            start: "top 85%",
            end: "bottom 15%",
          });
        }, 800);
        setTimeout(() => {
          this.influenceGridBackground('update');
        }, 20);
      }
    },
    showContentGridItem(el, gridId) {
      const filters = this.contentGrid[gridId]?.filters ?? false;

      const anyActiveFilters = filters ? Object.values(filters).some(f => f.active.length > 0) : false;

      if (!anyActiveFilters) return true;

      for (const filterCategory in filters) {
        const dataAttribute = `data-${filterCategory}`;
        const itemData = el.getAttribute(dataAttribute) ? JSON.parse(el.getAttribute(dataAttribute).replace(/'/g, '"')) : [];

        if (filters[filterCategory].active.length > 0 && itemData.length === 0) {
          return false;
        }

        const isActive = itemData.some(item =>
          filters[filterCategory].active.some(activeFilter =>
            activeFilter.slug === item.slug || activeFilter.slug.includes(item.slug)
          )
        );

        if (!isActive && filters[filterCategory].active.length > 0) {
          return false;
        }
      }

      return true;
    },
    toggleFilter(gridId, filterId, toggle = 'toggle') {
      if (toggle == 'show') {
        this.contentGrid[gridId].filters[filterId].status.open = true;
        return;
      }
      if (toggle == 'hide') {
        this.contentGrid[gridId].filters[filterId].status.open = false;
        return;
      }
      if (toggle == 'toggle') {
        this.contentGrid[gridId].filters[filterId].status.open = !this.contentGrid[gridId].filters[filterId].status.open;
      }
    },
    toggleParentTerm(gridId, filterId, termSlug, toggle = 'toggle') {
      const termObject = this.contentGrid[gridId].filters[filterId].terms.find(term => term.slug === termSlug);

      if (termObject) {
        if (toggle === 'show') {
          termObject.open = true;
          return;
        }
        if (toggle === 'hide') {
          termObject.open = false;
          return;
        }
        if (toggle === 'toggle') {
          termObject.open = !termObject.open;
        }
      }
    },
    toggleAllFilterTerms(gridId, filterId) {
      const targetFilter = this.contentGrid[gridId].filters[filterId];
      const isActive = targetFilter.active.length > 0;

      if (isActive) {
        targetFilter.active = [];
        return;
      }

      targetFilter.active = [];

      targetFilter.terms.forEach(term => {
        targetFilter.active.push(term);

        if (term.children) {
          targetFilter.active.push(...term.children);
        }
      });
    },
    toProperCase(filterId, ignoreWords = ['and', 'or', 'of', 'in', 'with', 'to', 'the']) {
      return filterId
        .split('-')
        .map((word, index) => {

          if (ignoreWords.includes(word) && index !== 0) {
            return word;
          }

          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    },
    toggleMenu(state = false) {
      if (!state) {
        this.navigation.menuOpen = !this.navigation.menuOpen;
        return;
      }
      if (state === 'open' && !this.navigation.menuOpen) {
        this.navigation.menuOpen = true;
      } else if (state === 'close' && this.navigation.menuOpen) {
        this.navigation.menuOpen = false;
      }
    },
    toggleSearch(state = false) {
      if (!state) {
        this.navigation.search = !this.navigation.search;
        return;
      }
      if (state === 'open' && !this.navigation.search) {
        this.navigation.search = true;
      } else if (state === 'close' && this.navigation.search) {
        this.navigation.search = false;
      }
    },
    toggleSubmenu(id, toggle = 'toggle') {
      const openSubMenu = (submenuId) => {
        this.navigation.submenuOpen[submenuId] = true;
        this.animateInMenu(submenuId);
      };

      if (this.navigation.submenuOpen[id] && this.navigation.submenuOpen[id] == true) {
        if (toggle == 'open') {
          return;
        }

        this.navigation.submenuOpen[id] = false;
        this.animateOutMenu(id);
        return;
      }

      if (toggle == 'open' && this.navigation.submenuOpen == {}) {
        openSubMenu(id);
      }

      const openId = Object.keys(this.navigation.submenuOpen).find(key => this.navigation.submenuOpen[key]);

      if (openId) {
        this.navigation.submenuOpen[openId] = false;
        this.animateOutMenu(openId, () => openSubMenu(id));
        return;
      }

      Object.keys(this.navigation.submenuOpen).forEach((key) => {
        if (key !== id) {
          this.animateOutMenu(key);
        }
      });

      openSubMenu(id);
    },
    animateInMenu(target) {
      gsap.fromTo(`[data-submenu-id='${target}']`,
        { height: 0, opacity: 0, display: 'none' },
        { duration: 0.6, height: 'auto', opacity: 1, display: 'block', ease: "power1.out", autoRound: false, overwrite: 'auto' }
      );

      gsap.fromTo(`[data-submenu-anchor-id='${target}']`,
        { width: 0, opacity: 0, display: 'none' },
        { duration: 0.6, width: '85px', opacity: 1, display: 'block', ease: "power1.out", autoRound: false, overwrite: 'auto' }
      );
    },
    animateOutMenu(target, callback = () => { }) {
      gsap.to(`[data-submenu-id='${target}']`,
        { duration: 0.6, height: 0, opacity: 0, display: 'none', ease: "power1.out", autoRound: false, overwrite: 'auto', onComplete: callback }
      );

      gsap.to(`[data-submenu-anchor-id='${target}']`,
        { duration: 0.6, width: 0, opacity: 0, display: 'none', ease: "power1.out", autoRound: false, overwrite: 'auto', onComplete: callback }
      );
    },
    scrollToTop() {
      if (window.location.pathname === '/') {
        gsap.to(window, {
          duration: 1,
          scrollTo: 0,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: 'body',
            start: 'top top',
          }
        });
      } else {
        window.location.href = '/';
      }
    },
    setIndexPosition(index) {
      this.team.indexPosition = index;
    },
    setScrollPos(value) {
      this.state.scrollPos = value;
    },
    initializeSlider(horizontalSlider, slideList) {
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: horizontalSlider,
          start: 'center center',
          end: 'center center',
          pin: horizontalSlider,
          scrub: 5,
          // pinSpacing: true,
        }
      });

      let sections = gsap.utils.toArray(slideList.children);

      let scrollTween = gsap.to(sections, {
        x: () => -(slideList.scrollWidth - horizontalSlider.clientWidth) + 'px',
        ease: 'none',
        duration: 10,
        scrollTrigger: {
          trigger: horizontalSlider,
          pin: true,
          start: 'center center',
          scrub: .01,
          invalidateOnRefresh: true,
          // end: `+=${slideList.offsetWidth}`,
          end: "325%",
        }
      });
    },
    getElementEffect(elementId) {
      let pixiElement = this.overlayContainer.children.find(child => child.name === elementId);
      return pixiElement ? pixiElement.effect : null;
    },
    setElementEffect(elementId, effect) {
      let pixiElement = this.overlayContainer.children.find(child => child.name === elementId);
      if (pixiElement) {
        pixiElement.effect = effect;
      }
    },
    freeStyleSlider() {
      let slidesContainer = document.querySelector(".freestyle-list");

      if (slidesContainer) {
        const slides = gsap.utils.toArray(".freestyle-list > *");
        // console.log(slides);
        let maxWidth = 0;

        const getMaxWidth = () => {
          maxWidth = window.innerWidth * 0.4;
          slides.forEach((section) => {
            maxWidth += section.offsetWidth;
          });
        };

        getMaxWidth();
        ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

        gsap.to(slides, {
          x: () => `-${maxWidth - window.innerWidth}`,
          ease: "none",
          scrollTrigger: {
            trigger: ".freestyle-slider",
            pin: true,
            pinnedContainer: ".freestyle-slider",
            start: "top top",
            scrub: 1.5,
            snap: {
              snapTo: 1 / (slides.length - 1),
              inertia: true,
              duration: { min: 0.1, max: 0.3 }
            },
            end: () => `+=${maxWidth}`,
            invalidateOnRefresh: true
          }
        });
      }
    },

    influenceGridBackground(state = 'init') {
      if (state === 'init') {
        let items = Array.from(document.querySelectorAll('.influence-item'));

        this.addBackground(items);
      }
      
      if (state === 'update') {
        let allItems = Array.from(document.querySelectorAll('.influence-item'));
        
        allItems.forEach(function (item) {
          item.classList.remove('bg-icon-1', 'bg-icon-2', 'bg-icon-3', 'bg-icon-4', 'bg-icon-5', 'bg-icon-6');
        });
        
        let items = Array.from(document.querySelectorAll('.content-grid-item-active'));
        
        this.addBackground(items);
      }
    },

    addBackground(items) {
      return items.forEach(function (item, index) {
        let remainder = (index % 6);
        switch (remainder) {
          case 0:
            item.classList.add('bg-icon-1');
            break;
          case 1:
            item.classList.add('bg-icon-2');
            break;
          case 2:
            item.classList.add('bg-icon-3');
            break;
          case 3:
            item.classList.add('bg-icon-4');
            break;
          case 4:
            item.classList.add('bg-icon-5');
            break;
          case 5:
            item.classList.add('bg-icon-6');
            break;
        }
      });
    }
  })
  Alpine.start();
}