import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

// Single PhotoSwipeLightbox Container
let lightbox = null;

document.addEventListener('alpine:init', () => {
  lightbox = window.Alpine.store('siteData').lightbox.container ?? null;
});

/* Lightbox Galleries */

// Initializes or retrieves the lightbox instance
function getLightbox() {
  if (window.Alpine.store('siteData')) {
    if (!window.Alpine.store('siteData').lightbox.container) {
      const newLightbox = new PhotoSwipeLightbox({
        pswpModule: PhotoSwipe,
        dataSource: [],
        showHideAnimationType: 'zoom',
      });
      window.Alpine.store('siteData').lightbox.container = newLightbox;
    }
    return window.Alpine.store('siteData').lightbox.container;
  }
  return null;
}

function setDataSource(lightbox, galleryItems) {
  lightbox.options.dataSource = galleryItems;
}

function getGalleryDataSource(galleryContainer, galleryId) {
  const storedData = window.Alpine.store('siteData').lightbox.galleryData[galleryId];
  if (storedData) {
    return storedData;
  }

  const uniqueSrcs = new Set();
  const galleryItems = [];

  let images = Array.from(galleryContainer.querySelectorAll('img'));

  if (galleryContainer.tagName === 'UL') {
    images = images.filter(img => !img.closest('li').className.includes('clone'));
  }

  images.forEach((img) => {
    const src = img.src;
    if (!uniqueSrcs.has(src)) {
      uniqueSrcs.add(src);
      galleryItems.push({
        src: src,
        width: img.naturalWidth,
        height: img.naturalHeight,
        alt: img.alt,
      });
    }
  });

  window.Alpine.store('siteData').lightbox.galleryData[galleryId] = galleryItems;
  return galleryItems;
}

function handleGalleryClick(galleryContainer, galleryId, lightbox) {
  galleryContainer.addEventListener('click', event => {
    let clickedImg = event.target.tagName === 'IMG' ? event.target : event.target.querySelector('img');
    if (!clickedImg) return;

    const galleryItems = getGalleryDataSource(galleryContainer, galleryId);
    let index;

    if (galleryContainer.tagName === 'UL') {
      const parentLi = clickedImg.closest('li');
      if (parentLi && !parentLi.className.includes('clone')) {
        const ariaLabel = parentLi.getAttribute('aria-label');
        const id = parentLi.getAttribute('id');

        if (ariaLabel) {
          index = parseInt(ariaLabel.match(/(\d+) of/)[1], 10) - 1;
        } else if (id) {
          const idMatch = id.match(/-slide(\d{2})$/);
          index = parseInt(idMatch ? idMatch[1].replace(/^0/, '') : 0, 10) - 1;
        }
      }
    } else {
      index = Array.from(galleryContainer.querySelectorAll('img')).indexOf(clickedImg);
    }

    if (index === undefined) return;

    setDataSource(lightbox, galleryItems);
    lightbox.options.index = index;
    lightbox.init();
    lightbox.loadAndOpen(index);
  });
}

export function imgLightboxGallery() {
  const lightbox = getLightbox();

  if (!lightbox) return;

  const galleryContainers = document.querySelectorAll('.lightbox-gallery');
  if (!galleryContainers.length) return;


  galleryContainers.forEach((galleryContainer, index) => {
    const galleryId = `gallery-${index}`;
    handleGalleryClick(galleryContainer, galleryId, lightbox);
  });
}

/* Individual Lightbox Images */

function getImageDataSource(imgElement, imageId) {
  const storedData = window.Alpine.store('siteData').lightbox.imageData[imageId];
  if (storedData) {
    return storedData;
  }

  const imageItem = {
    src: imgElement.src,
    width: imgElement.naturalWidth,
    height: imgElement.naturalHeight,
    alt: imgElement.alt,
  };

  window.Alpine.store('siteData').lightbox.imageData[imageId] = [imageItem];

  return [imageItem];
}

function handleImageClick(event, lightbox) {
  let clickedElem = event.target;
  let clickedImg = clickedElem.tagName === 'IMG' ? clickedElem : clickedElem.querySelector('img');
  
  if (!clickedImg) return;

  const srcArray = clickedImg.src.split('/');
  const filename = srcArray[srcArray.length - 1];

  const imageId = filename;

  const imageItems = getImageDataSource(clickedImg, imageId);

  setDataSource(lightbox, imageItems);
  lightbox.options.index = 0;

  lightbox.init();
  lightbox.loadAndOpen(0);
}

export function imgLightboxSingle() {
  const lightbox = getLightbox();

  if (!lightbox) return;

  const individualImages = document.querySelectorAll('.is-style-lightbox, .lightbox-img, .lightbox-thumbnail');
  if (!individualImages.length) return;

  individualImages.forEach((img) => {
    img.addEventListener('click', function(event) {
      handleImageClick(event, lightbox);
    });
  });
}

export function imgLightboxGlobal() {
  imgLightboxSingle();
  imgLightboxGallery();
}
