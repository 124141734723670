import Swiper from 'swiper';
import { Navigation, FreeMode, Mousewheel } from 'swiper/modules';

export const horizontalSlider = () => {
  let sliderContainers = document.querySelectorAll('.content-slider-container');
  if (sliderContainers.length === 0) return;


  sliderContainers.forEach((sliderEl) => {
    const sliderPrefix = 'content';
    let containerClass = `.${sliderPrefix}-slider-container`;
    let wrapperClass = `${sliderPrefix}-slider-wrapper`;
    let slideClass = `${sliderPrefix}-slide`;
    let paginationClass = `${sliderPrefix}-slider-pagination`;

    if (sliderEl instanceof HTMLElement) {
      if (!sliderEl.id) {
        sliderEl.id = `slider-container-${Math.random().toString(36).slice(2, 9)}`;
      }
      const containerSelector = `#${sliderEl.id}`;

      return new Swiper(containerSelector, {
        modules: [Navigation, FreeMode, Mousewheel],
        direction: 'horizontal',
        wrapperClass: wrapperClass,
        slideClass: slideClass,
        slidesPerView: 'auto',

        slidesOffsetAfter: 40,
        speed: 600,
        updateOnWindowResize: true,
        simulateTouch: true,
        freeMode: {
          enabled: true,
          sticky: false,
        },
        mousewheel: {
          forceToAxis: true,
          releaseOnEdges: true
        },
        scrollbar: {
          draggable: true,
        },
        navigation: {
          prevEl: `.freestyle-button-prev`,
          nextEl: `.freestyle-button-next`,
        }
      })
    }
  });
}