import Splide from '@splidejs/splide';

export const homeHeroSlider = () => {
  const heroSliderContainer = document.querySelectorAll('.hero-splide');
  if (heroSliderContainer.length === 0) return;
  
  heroSliderContainer.forEach(heroSlider => {
    const sliderData = JSON.parse(heroSlider.getAttribute('data-slider'));
    
    if(sliderData) {
      heroSlider.removeAttribute('data-slider');
    }
    
    const { sliderId, navSliderId, progressBarClass } = sliderData;

    var autoplayInterval = 4000;
    var timer;

    var mainSlider = new Splide(`.${sliderId}`, {
      type: 'fade',
      arrows: false,
      easing: 'ease',
      lazyLoad: 'nearby',
      pagination: false,
      autoplay: true,
      rewind: true,
      interval: autoplayInterval,
      // arrows:true,
      breakpoints: {
        1024: {
          autoplay: true,
        },

      }
    });

    var navSlider = new Splide(`.${navSliderId}`, {
      arrows: false,
      perPage: 4,
      focus: 'center',
      gap: 10,
      pagination: false,
      cover: true,
      isNavigation: true,
      breakpoints: {
        1023: {
          perPage: 4,
        },
        1440: {
          perPage: 3,
        },
      }
    });

    var mainSliderEl = document.querySelector(`.${sliderId}`);

    mainSliderEl.addEventListener('mouseenter', function () {
      mainSlider.options.autoplay = false;
    });

    mainSliderEl.addEventListener('mouseleave', function () {
      mainSlider.options.autoplay = true;
    });

    var progressBars = document.querySelectorAll(`.${progressBarClass}`);
    
    mainSlider.on('active', function () {
      clearInterval(timer);
      var progressBar = progressBars[mainSlider.index];
      progressBar.style.width = '0%';
      timer = setInterval(function () {
        progressBar.style.width = Math.min(100, parseFloat(progressBar.style
          .width) + 100 / (autoplayInterval / 100)) + '%';
      }, 100);
    });

    mainSlider.on('moved', function () {
      clearInterval(timer);
      progressBars.forEach(function (progressBar) {
        progressBar.style.width = '0%';
      });
    });

    navSlider.on('mounted', function () {
      var navSlides = document.querySelectorAll(`.${navSliderId} .splide__slide`);
      navSlides.forEach(function (slide, index) {
        slide.addEventListener('mouseenter', function () {
          mainSlider.go(
            index); // Go to the corresponding main slide on hover
        });
      });
    });

    mainSlider.sync(navSlider).mount();
    navSlider.mount();

  });
}