import domReady from '@roots/sage/client/dom-ready';
import { dataStore } from './data/data-store.js';
import { imgLightboxGlobal } from './lightbox/lightbox.js';
import { slidersInit } from './blocks/sliders/index.js';
import { animatedStyledHeading } from './blocks/styled-heading-animations.js';

/**
 * Application entrypoint
 */
domReady(async () => {
  dataStore();
  imgLightboxGlobal();
  slidersInit();
  animatedStyledHeading();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept((err) => {
    if (err) console.error(err);

  });
}