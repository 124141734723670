import Splide from '@splidejs/splide';

export const teamSlider = () => {
  const teamSliderContainer = document.querySelectorAll('.team-slider-block');
  if (teamSliderContainer.length === 0) return;

  teamSliderContainer.forEach(teamSlider => {
    const sliderData = JSON.parse(teamSlider.getAttribute('data-slider'));
    const { sliderId } = sliderData;

    if (sliderData && sliderId) {
      teamSlider.removeAttribute('data-slider');
    }

    var thumbnailSliderId = sliderId + '-thumbnails';
    var mainSlider;
    var thumbnailSlider;

    var alpineStore = window.Alpine && window.Alpine.store && window.Alpine.store('siteData');
    var startIndex = alpineStore.team.indexPosition;

    var thumbnailElement = document.querySelector('.' + thumbnailSliderId);
    if (thumbnailElement) {
      var firstSlide = thumbnailElement.querySelector('.splide__slide');
      if (firstSlide) {
        firstSlide.remove();
      }
    }
    var mainSlider = initializeMainSlider(sliderId, startIndex);
    var thumbnailSlider = initializeThumbnailSlider(thumbnailSliderId, startIndex);

    if (mainSlider && thumbnailSlider) {
      mainSlider.sync(thumbnailSlider);
    }

    // Update the button style on page load.
    toggleButtonStyle();

    window.addEventListener('resize', function () {
      // Update the button style on page load.
      toggleButtonStyle();
    });

    function toggleButtonStyle() {
      var buttons = document.querySelectorAll('.leadership-btn');
      var links = document.querySelectorAll('.leadership-btn__link');

      buttons.forEach(function (button) {
        if (window.innerWidth < 1024) {
          button.classList.remove('is-style-default');
          button.classList.add('is-style-arrow');
        } else {
          button.classList.remove('is-style-arrow');
          button.classList.add('is-style-default');
        }
      });

      // links.forEach(function(link) {
      //     if (window.innerWidth < 1024) {
      //         link.classList.remove(
      //             'has-gradient-orange-red-gradient-background');

      //     } else {
      //         link.classList.add('has-gradient-orange-red-gradient-background');
      //     }
      // });
    }

    function initializeMainSlider(sliderId, startIndex) {
      var main = new Splide('.' + sliderId, {
        arrows: true,
        type: 'fade',
        easing: 'ease',
        perPage: 1,
        perMove: 1,
        fixedWidth: 888,
        start: startIndex,
        lazyLoad: 'nearby',
        pagination: false,
        breakpoints: {
          1024: {
            destroy: true,
          },
        }
      }).mount();

      return main;

      return null;
    }

    function initializeThumbnailSlider(thumbnailSliderId, startIndex) {
      return new Splide('.' + thumbnailSliderId, {
        arrows: false,
        type: 'slide',
        easing: 'ease',
        perPage: 3,
        start: startIndex,
        // focus: 'center',
        // lazyLoad: 'nearby',
        pagination: false,
        fixedWidth: 225,
        drag: false,
        fixedHeight: 255,
        gap: '2rem',
        cover: false,
        breakpoints: {
          1024: {
            destroy: true,
          },
        }
      }).mount();
      return null;
    }
  });
}